import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { ResponsiveMaxWidthContent } from "../../ui/components/MaxWidthContent/MaxWidthContent"
import {
  StyledCompactText,
  StyledFancyHeading,
  StyledInfoBlockImageDefault,
  StyledInfoLive,
  StyledPeriod,
  StyledRed,
} from "../CoachSignUpScreen/components/TopSection/TopSection"
import { StyledBoldHeading } from "../CoachSignUpScreen/components/PurpleSection/PurpleSection"
import { desktopGridCss, mobileGridCss } from "../../ui/shared"
import theme from "../../ui/theme"
import ActionButton from "../../ui/buttons/ActionButton/ActionButton"
import MediumHeading from "../../ui/typography/MediumHeading/MediumHeading"
import AboutYouspired, {
  StyledExploreButton,
} from "../../components/AboutYouspired/AboutYouspired"
import { ROUTES } from "../../navigation/routes"
import InfoStep from "./components/InfoStep/InfoStep"
import ChannelIcon from "../../assets/inline-assets/channel.svg"
import DashboardIcon from "../../assets/inline-assets/dashboard.svg"
import FAQIcon from "../../assets/inline-assets/faq.svg"
import SearchIcon from "../../assets/inline-assets/search.svg"
import VideoIcon from "../../assets/inline-assets/video.svg"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  StyledDesktopOnly,
} from "../../ui/responsive"
import {
  getAboutScreenConclusionText,
  getAboutScreenDesktopIntro,
  getAboutScreenHowItWorksBlocks,
  getAboutScreenHowItWorksCTADestination,
  getAboutScreenHowItWorksCTALabel,
  getAboutScreenHowItWorksImage,
  getAboutScreenIntroBlocks,
  IAboutScreenGraph,
} from "../../data/cms/aboutScreen"
import { retinaMediaQuery } from "../../ui/helpers"

const StyledTopSection = styled.section`
  margin-top: 35.5px;
  margin-bottom: 73px;

  ${DESKTOP_BREAKPOINT} {
    margin-top: 100px;
  }
`

const StyledInfoContainer = styled.div`
  padding-bottom: 64px;
  overflow-x: hidden;

  ${DESKTOP_BREAKPOINT} {
    padding-bottom: 106px;
  }
`

const StyledInfoContent = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -206px;
      bottom: -48px;
      height: 744px;
      width: 425.61px;
      border: 4px solid ${theme.colors.secondary};
      transform: rotate(-30deg);
      z-index: -1;
    }
  }
`

const StyledInfoBlock = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${mobileGridCss};
    grid-auto-flow: dense;
    margin-top: 28.5px;
    align-items: center;
  }

  ${DESKTOP_BREAKPOINT} {
    display: flex;
    align-items: center;
    &:nth-child(1) {
      grid-column: 4 / span 3;
      flex-direction: row-reverse;
    }
    &:nth-child(2) {
      grid-column: 1 / span 3;
      margin-top: -450px;
    }
    &:nth-child(3) {
      grid-column: 4 / span 3;
      flex-direction: row-reverse;
      margin-top: 106px;
    }
  }
`

const StyledInfoBlockImageWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    grid-column: span 2;
    margin-right: 26px;
    margin-left: -49px;

    div:nth-of-type(odd) > & {
      grid-column: 3 / span 2;
      margin-right: -49px;
      margin-left: 36px;
    }
  }

  ${DESKTOP_BREAKPOINT} {
    div:nth-of-type(odd) > & {
      margin-left: 57px;
    }
    div:nth-of-type(even) > & {
      margin-right: 40px;
    }
  }
`

const StyledInfoBlockTextContainer = styled.div`
  ${MOBILE_BREAKPOINT} {
    grid-column: span 2;

    div:nth-of-type(odd) > & {
      grid-column: 1 / span 2;
      margin-right: -${theme.grid.mobileGutterWidth}px;
    }
  }

  p {
    margin-top: 8px;
  }
`

const StyledPurpleSection = styled.section`
  background: ${theme.colors.secondary};
  color: ${theme.colors.white};
  padding-top: 78px;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 97px;
  }
`

const StyledPurpleSectionContent = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
    padding-bottom: 123px;
    position: relative;
  }
`

const StyledPurpleSectionHeader = styled.header`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
  }
`

const StyledPurpleSectionMain = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 3;
  }
`

const StyledButtonWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    display: flex;
    justify-content: center;

    a {
      max-width: 360px;
    }
  }
`

const StyledFancyList = styled.ul`
  margin-top: 54px;
  margin-bottom: 39px;
  position: relative;
  overflow: hidden;

  ${DESKTOP_BREAKPOINT} {
    margin-top: 12px;
    margin-bottom: 56px;
  }

  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 19px;
    bottom: 0;
    width: 2px;
    background: ${theme.colors.primary};
  }

  > li {
    display: flex;
  }
`

const StyledBottomImage = styled.div<{
  image: string
  image2x: string
}>`
  background-image: url(${props => props.image});
  ${retinaMediaQuery} {
    background-image: url("${props => props.image2x}");
  }
  background-position: left bottom;
  padding-top: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 416px;

  ${DESKTOP_BREAKPOINT} {
    width: 340px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`

const StyledDarkSection = styled.section`
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  padding-top: 93px;
  //padding-bottom: 83px;

  ${DESKTOP_BREAKPOINT} {
    padding-bottom: 30px;
  }

  h3 {
    margin-bottom: 24px;
  }
`

const StyledDarkSectionContentWrapper = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
  }
`

const StyledDarkSectionContent = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: 3 / span 2;
  }
`

const StyledMediumHeading = styled(MediumHeading)`
  line-height: 28px;
`

const StyledTopSectionContent = styled(ResponsiveMaxWidthContent)`
  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
  }
`

const StyledTopContentWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
  }
`

const StyledIntroText = styled.p`
  font-size: ${theme.fontSizes.medium}px;
  margin-top: 40px;
`

const StyledHeading = styled(StyledFancyHeading)`
  span:first-child {
    margin-left: -3px;
  }
  ${MOBILE_BREAKPOINT} {
    line-height: 36px;
  }

  ${DESKTOP_BREAKPOINT} {
    font-size: 80px;
    line-height: 38px;
    margin-right: -40px;
  }
`

const StyledHeadingPeriod = styled(StyledPeriod)`
  ${MOBILE_BREAKPOINT} {
    font-size: 38px;
  }
`

const StyledHeadingCompactText = styled(StyledCompactText)`
  ${DESKTOP_BREAKPOINT} {
    font-size: 44px;
    line-height: 30px;
  }
`

const StyledHowItWorksHeading = styled(StyledFancyHeading)`
  line-height: 36px;
  span:first-child {
    margin-left: -3px;
  }
`

const AboutScreen: React.FC<{
  content: IAboutScreenGraph
}> = ({ content }) => {
  const introBlocks = getAboutScreenIntroBlocks(content)
  const howItWorksBlocks = getAboutScreenHowItWorksBlocks(content)
  const [howItWorksImage, howItWorksImage2x] = getAboutScreenHowItWorksImage(
    content
  )
  return (
    <div>
      <StyledTopSection>
        <StyledTopSectionContent>
          <StyledTopContentWrapper>
            <StyledHeading>
              <StyledRed>Hear it</StyledRed>
              <br />
              <StyledHeadingCompactText>
                like you've never heard before
              </StyledHeadingCompactText>
              <StyledHeadingPeriod>.</StyledHeadingPeriod>
            </StyledHeading>
            <StyledDesktopOnly>
              <StyledIntroText>
                {getAboutScreenDesktopIntro(content)}
              </StyledIntroText>
            </StyledDesktopOnly>
          </StyledTopContentWrapper>
        </StyledTopSectionContent>
      </StyledTopSection>
      <StyledInfoContainer>
        <StyledInfoContent>
          <StyledInfoBlock>
            <StyledInfoBlockImageWrapper>
              <StyledInfoBlockImageDefault image={introBlocks[0].image.url} />
            </StyledInfoBlockImageWrapper>
            <StyledInfoBlockTextContainer>
              <StyledInfoLive>Live</StyledInfoLive>
              <StyledBoldHeading>{introBlocks[0].heading}</StyledBoldHeading>
              <p>{introBlocks[0].description}</p>
            </StyledInfoBlockTextContainer>
          </StyledInfoBlock>
          <StyledInfoBlock>
            <StyledInfoBlockImageWrapper>
              <StyledInfoBlockImageDefault image={introBlocks[1].image.url} />
            </StyledInfoBlockImageWrapper>
            <StyledInfoBlockTextContainer>
              <StyledBoldHeading>{introBlocks[1].heading}</StyledBoldHeading>
              <p>{introBlocks[1].description}</p>
            </StyledInfoBlockTextContainer>
          </StyledInfoBlock>
          <StyledInfoBlock>
            <StyledInfoBlockImageWrapper>
              <StyledInfoBlockImageDefault image={introBlocks[2].image.url} />
            </StyledInfoBlockImageWrapper>
            <StyledInfoBlockTextContainer>
              <StyledBoldHeading>{introBlocks[2].heading}</StyledBoldHeading>
              <p>{introBlocks[2].description}</p>
            </StyledInfoBlockTextContainer>
          </StyledInfoBlock>
        </StyledInfoContent>
      </StyledInfoContainer>
      <StyledPurpleSection>
        <StyledPurpleSectionContent>
          <StyledPurpleSectionHeader>
            <StyledHowItWorksHeading>
              What
              <br />
              <StyledCompactText>is Youspired</StyledCompactText>
              <StyledPeriod>.</StyledPeriod>
            </StyledHowItWorksHeading>
          </StyledPurpleSectionHeader>
          <StyledPurpleSectionMain>
            <StyledFancyList>
              <li>
                <InfoStep
                  heading={howItWorksBlocks[0].heading}
                  description={howItWorksBlocks[0].description}
                  icon={<SearchIcon />}
                />
              </li>
              <li>
                <InfoStep
                  heading={howItWorksBlocks[1].heading}
                  description={howItWorksBlocks[1].description}
                  icon={<ChannelIcon />}
                />
              </li>
              <li>
                <InfoStep
                  heading={howItWorksBlocks[2].heading}
                  description={howItWorksBlocks[2].description}
                  icon={<VideoIcon />}
                />
              </li>
              <li>
                <InfoStep
                  heading={howItWorksBlocks[3].heading}
                  description={howItWorksBlocks[3].description}
                  icon={<DashboardIcon />}
                />
              </li>
              <li>
                <InfoStep
                  heading={howItWorksBlocks[4].heading}
                  description={howItWorksBlocks[4].description}
                  icon={<FAQIcon />}
                />
              </li>
            </StyledFancyList>
            <StyledButtonWrapper>
              <ActionButton
                as={Link}
                to={getAboutScreenHowItWorksCTADestination(content)}
              >
                {getAboutScreenHowItWorksCTALabel(content)}
              </ActionButton>
            </StyledButtonWrapper>
          </StyledPurpleSectionMain>
          <StyledBottomImage
            image={howItWorksImage}
            image2x={howItWorksImage2x}
          >
          </StyledBottomImage>
        </StyledPurpleSectionContent>
      </StyledPurpleSection>
      <StyledDarkSection>
        <StyledDarkSectionContentWrapper>
          <StyledDarkSectionContent>
            <StyledFancyHeading as="h3">
              Our <StyledRed>Y?</StyledRed>
            </StyledFancyHeading>
            <StyledMediumHeading as="h4">
              {getAboutScreenConclusionText(content)}
            </StyledMediumHeading>
          </StyledDarkSectionContent>
        </StyledDarkSectionContentWrapper>
      </StyledDarkSection>
      <AboutYouspired includeCopy={false} />
    </div>
  )
}

export default AboutScreen
