import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import {
  desktopMaxWidthContentCss,
  maxWidthContentCss,
} from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import {
  StyledCompactText,
  StyledFancyHeading,
  StyledPeriod,
} from "../TopSection/TopSection"
import theme from "../../../../ui/theme"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../../../ui/responsive"
import { desktopGridCss } from "../../../../ui/shared"
import {
  getCoachInfoScreenSubstanceText,
  getCoachInfoScreenTypicalHeading,
  getCoachInfoScreenTypicalList,
  getCoachInfoScreenYouspiredContentHeading,
  getCoachInfoScreenYouspiredContentList,
  ICoachInfoScreenGraph,
} from "../../../../data/cms/coachInfoScreen"

const StyledPurpleSection = styled.section`
  background: ${theme.colors.secondary};
  color: ${theme.colors.white};
  padding-top: 40px;
  padding-bottom: 68px;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 112px;
    padding-bottom: 132px;
  }
`

const StyledContent = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss};
  }
  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
    ${desktopGridCss};
  }
`

const StyledInfo = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
  }
`

const StyledSubstanceHeader = styled(StyledFancyHeading)`
  line-height: 52px;
  margin-bottom: 4px;
`

const StyledCrossedOutListSection = styled.div`
  ${MOBILE_BREAKPOINT} {
    margin-top: 34px;
    margin-bottom: 48px;
  }

  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
    margin-top: 9px;
  }

  h3 {
    margin-bottom: 7px;
  }

  ul {
    font-size: ${theme.fontSizes.mediumPlus}px;
    line-height: 30px;

    span {
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        pointer-events: none;
        position: absolute;
        left: -2px;
        right: -12px;
        top: 50%;
        height: 4px;
        background: ${theme.colors.primary};
      }
    }
  }
`

export const StyledBoldHeading = styled.h3`
  font-size: ${theme.fontSizes.mediumPlus}px;
  line-height: 30px;
  font-weight: ${theme.fontWeights.bold};
`

const StyledLargerBoldHeading = styled(StyledBoldHeading)`
  ${DESKTOP_BREAKPOINT} {
    font-size: 30px;
    line-height: 36px;
  }
`

const StyledUnderlinedListSection = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
    margin-top: 9px;
  }

  h3 {
    margin-bottom: 16.5px;
  }

  ul {
    font-size: ${theme.fontSizes.mediumPlus}px;
    line-height: 30px;
    li {
      border-bottom: 4px solid ${theme.colors.primary};
      padding-bottom: 8px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
`

const PurpleSection: React.FC<{
  content: ICoachInfoScreenGraph
}> = ({ content }) => {
  return (
    <StyledPurpleSection>
      <StyledContent>
        <StyledInfo>
          <StyledSubstanceHeader>
            <StyledCompactText>Substance,</StyledCompactText>
            <br />
            not <br />
            social<StyledPeriod>.</StyledPeriod>
          </StyledSubstanceHeader>
          <p>{getCoachInfoScreenSubstanceText(content)}</p>
        </StyledInfo>
        <StyledCrossedOutListSection>
          <StyledBoldHeading>
            {getCoachInfoScreenTypicalHeading(content)}
          </StyledBoldHeading>
          <ul>
            {getCoachInfoScreenTypicalList(content).map(text => {
              return (
                <li key={text}>
                  <span>{text}</span>
                </li>
              )
            })}
          </ul>
        </StyledCrossedOutListSection>
        <StyledUnderlinedListSection>
          <StyledLargerBoldHeading>
            {getCoachInfoScreenYouspiredContentHeading(content)}
          </StyledLargerBoldHeading>
          <ReactMarkdown
            source={getCoachInfoScreenYouspiredContentList(content)}
          />
        </StyledUnderlinedListSection>
      </StyledContent>
    </StyledPurpleSection>
  )
}

export default PurpleSection
