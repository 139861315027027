import React, { useState } from "react"
import styled, { css } from "styled-components"
import theme from "../../../../ui/theme"
import { StyledListIconDefault } from "../../../CoachSignUpScreen/components/SampleAndTeachSection/SampleAndTeachSection"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import ArrowBottom from "../../../../assets/inline-assets/arrowBottom.svg"

const StyledListIconWrapper = styled.div`
  margin: 28px 0;
  margin-bottom: 18px;
  margin-right: 16.5px;
  position: relative;

  li:first-child > &,
  li:last-child > & {
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      height: 400px;
      width: 6px;
      margin-left: -3px;
      background: ${theme.colors.secondary};
    }
  }
  li:first-child > & {
    &::before {
      bottom: 100%;
    }
  }
  li:last-child > & {
    &::before {
      top: 0;
    }
  }
`

const StyledListIcon = styled(StyledListIconDefault)`
  background: ${theme.colors.secondary};
  position: relative;

  svg {
    path {
      stroke: ${theme.colors.white};
    }
  }
`

const StyledListText = styled.div`
  border-top: 1px solid #b3b1de;
  padding: 20px 0;
  flex: 1;

  li:last-child > & {
    border-bottom: 1px solid #b3b1de;
  }
`

const StyledHeader = styled.header`
  display: flex;

  h4 {
    flex: 1;
  }
`

const flipCss = css`
  transform: scaleY(-1);
`

const StyledToggleButton = styled.button<{
  open: boolean
}>`
  padding: 0 7px;
  margin-right: 2px;
  svg {
    ${props => (props.open ? flipCss : "")};
    path {
      stroke: ${theme.colors.white};
    }
  }
`

const InfoStep: React.FC<{
  heading: string
  description: string
  icon: any
}> = ({ heading, description, icon }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <StyledListIconWrapper>
        <StyledListIcon>{icon}</StyledListIcon>
      </StyledListIconWrapper>
      <StyledListText>
        <StyledHeader>
          <MediumPlusHeading as="h4">{heading}</MediumPlusHeading>
          <StyledToggleButton open={open} onClick={() => setOpen(!open)}>
            <ArrowBottom width="18" />
          </StyledToggleButton>
        </StyledHeader>
        {open && <p>{description}</p>}
      </StyledListText>
    </>
  )
}

export default InfoStep
