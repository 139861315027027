import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import AboutScreen from "../screens/AboutScreen/AboutScreen"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import { IAboutScreenGraph } from "../data/cms/aboutScreen"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { ROUTES } from "../navigation/routes"
import { getEnvKey } from "../utils/env"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  datoCmsWebAboutScreen?: IAboutScreenGraph
  datoCmsWebAboutScreenPrd?: IAboutScreenGraph
  datoCmsWebAboutScreenStg?: IAboutScreenGraph
  datoCmsWebAboutScreenDev?: IAboutScreenGraph
}

interface Props {
  data: Data
}

const AboutPage: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebAboutScreenDev",
    stgKey: "datoCmsWebAboutScreenStg",
    uatKey: "datoCmsWebAboutScreen",
    prdKey: "datoCmsWebAboutScreenPrd",
    defaultKey: "datoCmsWebAboutScreen",
  })
  const content: IAboutScreenGraph = get(props, `data.${contentKey}`)
  if (!content) throw new Error(`datoCmsWebAboutScreen is missing`)
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation pageName={ROUTES.about.name}>
          <PageMetaTags data={props} />
          <AboutScreen content={content} />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default AboutPage

export const query = graphql`
  query AboutScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebAboutScreen @include(if: $uatEnv) {
      ...AboutScreen
    }
    datoCmsWebAboutScreenPrd @include(if: $prdEnv) {
      ...AboutScreenPrd
    }
    datoCmsWebAboutScreenStg @include(if: $stgEnv) {
      ...AboutScreenStg
    }
    datoCmsWebAboutScreenDev @include(if: $devEnv) {
      ...AboutScreenDev
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: aboutScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: aboutScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: aboutScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: aboutScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
