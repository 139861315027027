import { graphql } from "gatsby"
import { get } from "lodash"

export interface IAboutScreenGraph {
  desktopIntro: string
  introBlocks: {
    heading: string
    description: string
    image: {
      url: string
    }
  }[]
  howItWorksBlock: {
    heading: string
    description: string
  }[]
  howItWorksImage: {
    url: string
  }
  howItWorksImage2x: {
    url: string
  }
  howItWorksCtaLabel: string
  howItWorksCtaDestination: string
  conclusionText: string
}

export const getAboutScreenHowItWorksCTALabel = (
  content: IAboutScreenGraph
): string => {
  return get(content, "howItWorksCtaLabel", "") || ""
}

export const getAboutScreenHowItWorksCTADestination = (
  content: IAboutScreenGraph
): string => {
  return get(content, "howItWorksCtaDestination", "") || ""
}

export const getAboutScreenHowItWorksImage = (
  content: IAboutScreenGraph
): [string, string] => {
  const image = get(content, "howItWorksImage.url", "")
  const image2x = get(content, "howItWorksImage2x.url", "")
  return [image, image2x]
}

export const getAboutScreenHowItWorksBlocks = (
  content: IAboutScreenGraph
): IAboutScreenGraph["howItWorksBlock"] => {
  const blocks = get(content, "howItWorksBlock", [])
  if (!blocks) return []
  return blocks
}

export const getAboutScreenIntroBlocks = (
  content: IAboutScreenGraph
): IAboutScreenGraph["introBlocks"] => {
  const blocks = get(content, "introBlocks", [])
  if (!blocks) return []
  return blocks
}

export const getAboutScreenDesktopIntro = (
  content: IAboutScreenGraph
): string => {
  return get(content, "desktopIntro", "") || ""
}

export const getAboutScreenConclusionText = (
  content: IAboutScreenGraph
): string => {
  return get(content, "conclusionText", "") || ""
}

export const query = graphql`
  fragment AboutScreen on DatoCmsWebAboutScreen {
    desktopIntro
    introBlocks {
      heading
      description
      image {
        url
      }
    }
    howItWorksBlock {
      heading
      description
    }
    howItWorksImage {
      url
    }
    howItWorksImage2x {
      url
    }
    howItWorksCtaLabel
    howItWorksCtaDestination
    conclusionText
  }

  fragment AboutScreenPrd on DatoCmsWebAboutScreenPrd {
    desktopIntro
    introBlocks {
      heading
      description
      image {
        url
      }
    }
    howItWorksBlock {
      heading
      description
    }
    howItWorksImage {
      url
    }
    howItWorksImage2x {
      url
    }
    howItWorksCtaLabel
    howItWorksCtaDestination
    conclusionText
  }

  fragment AboutScreenStg on DatoCmsWebAboutScreenStg {
    desktopIntro
    introBlocks {
      heading
      description
      image {
        url
      }
    }
    howItWorksBlock {
      heading
      description
    }
    howItWorksImage {
      url
    }
    howItWorksImage2x {
      url
    }
    howItWorksCtaLabel
    howItWorksCtaDestination
    conclusionText
  }

  fragment AboutScreenDev on DatoCmsWebAboutScreenDev {
    desktopIntro
    introBlocks {
      heading
      description
      image {
        url
      }
    }
    howItWorksBlock {
      heading
      description
    }
    howItWorksImage {
      url
    }
    howItWorksImage2x {
      url
    }
    howItWorksCtaLabel
    howItWorksCtaDestination
    conclusionText
  }
`
