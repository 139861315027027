import React from "react"
import styled from "styled-components"
import {
  StyledCompactText,
  StyledFancyHeading,
  StyledPeriod,
  StyledPurple,
} from "../TopSection/TopSection"
import ActionButton from "../../../../ui/buttons/ActionButton/ActionButton"
import {
  desktopMaxWidthContentCss,
  maxWidthContentCss,
  MobileOnlyMaxWidthContent,
} from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import PaymentIcon from "../../../../assets/inline-assets/payment.svg"
import ThumbnailIcon from "../../../../assets/inline-assets/thumbnail.svg"
import RecordIcon from "../../../../assets/inline-assets/record.svg"
import { bgImageCss, desktopGridCss } from "../../../../ui/shared"
import theme from "../../../../ui/theme"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../../../ui/responsive"
import {
  getCoachInfoScreenSampleLiveBackgroundImage,
  getCoachInfoScreenTeachList,
  getCoachInfoScreenTeachText,
  ICoachInfoScreenGraph,
} from "../../../../data/cms/coachInfoScreen"

const StyledContainer = styled.div`
  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
    ${desktopGridCss};
    padding-bottom: 135px;
  }
`

const StyledSampleSectionWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 4;
    padding-right: 84px;
  }
`

const StyledSampleSection = styled.section<{
  image: string
}>`
  ${bgImageCss};
  background-image: url(${props => props.image});
  color: ${theme.colors.white};
  height: 460px;
  height: ${(460 / 414) * 100}vw;

  ${MOBILE_BREAKPOINT} {
    padding-bottom: 34px;
  }

  ${DESKTOP_BREAKPOINT} {
    height: 755px; // todo  - responsive
    margin-top: -100px;
    border-radius: 36px;
    padding-left: 56px;
    display: flex;
    align-items: center;
  }
`

const StyledSampleSectionContent = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  ${DESKTOP_BREAKPOINT} {
    max-width: 211px;
  }
`

const StyledSampleSectionHeadingWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    flex: 1;
    display: flex;
    align-items: center;
  }

  ${DESKTOP_BREAKPOINT} {
    margin-bottom: 23px;
  }

  > h2 {
    line-height: 52px;
  }
`

const StyledTeachSection = styled.section`
  ${MOBILE_BREAKPOINT} {
    margin-top: 58px;
    margin-bottom: 65px;
  }

  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
    margin-top: 146.5px;
  }
`

const StyledTeachDescription = styled.div`
  margin-top: 33px;
  margin-bottom: 31px;
`

export const StyledIconList = styled.ul`
  font-size: 24px;
  line-height: ${theme.fontSizes.mediumPlus}px;
  font-weight: ${theme.fontWeights.bold};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 19px;
    top: 5px;
    bottom: 5px;
    width: 2px;
    background: ${theme.colors.primary};
  }

  > li:not(:first-child) {
    margin-top: 13px;
  }

  > li {
    display: flex;
    position: relative;
    align-items: center;
  }
`

export const StyledListIconDefault = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 20px;
    position: relative;
    top: 1px;

    path {
    }
  }
`

export const StyledListIcon = styled(StyledListIconDefault)<{
  dark?: boolean
}>`
  background: ${props =>
    props.dark ? theme.colors.darkGrey : theme.colors.white};
  margin-right: 22.5px;

  svg {
    path {
      stroke: ${props =>
        props.dark ? theme.colors.white : theme.colors.lightGrey};
    }
  }
`

export const StyledListContent = styled.div`
  flex: 1;

  h3 {
    margin-top: 7px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-top: 8px;
  }
`

const SampleAndTeachSection: React.FC<{
  content: ICoachInfoScreenGraph
}> = ({ content }) => {
  const teachList = getCoachInfoScreenTeachList(content)
  return (
    <StyledContainer>
      <StyledSampleSectionWrapper>
        <StyledSampleSection
          image={getCoachInfoScreenSampleLiveBackgroundImage(content)}
        >
          <StyledSampleSectionContent>
            {/* <StyledSampleSectionHeadingWrapper> */}
            {/*  <StyledFancyHeading> */}
            {/*    <StyledCompactText>Watch a sample</StyledCompactText> */}
            {/*    <br /> live<StyledPeriod>.</StyledPeriod> */}
            {/*  </StyledFancyHeading> */}
            {/* </StyledSampleSectionHeadingWrapper> */}
            {/* <div> */}
            {/*  <ActionButton>View Live</ActionButton> */}
            {/* </div> */}
          </StyledSampleSectionContent>
        </StyledSampleSection>
      </StyledSampleSectionWrapper>
      <StyledTeachSection>
        <MobileOnlyMaxWidthContent>
          <StyledFancyHeading>
            <StyledCompactText>Teach and</StyledCompactText>
            <br />
            <StyledPurple>Earn</StyledPurple>
            <StyledPeriod>.</StyledPeriod>
          </StyledFancyHeading>
          <StyledTeachDescription>
            <p>{getCoachInfoScreenTeachText(content)}</p>
          </StyledTeachDescription>
          <StyledIconList>
            <li>
              <StyledListIcon>
                <PaymentIcon />
              </StyledListIcon>
              {teachList[0]}
            </li>
            <li>
              <StyledListIcon>
                <ThumbnailIcon />
              </StyledListIcon>
              {teachList[1]}
            </li>
            <li>
              <StyledListIcon>
                <RecordIcon />
              </StyledListIcon>
              {teachList[2]}
            </li>
            <li>
              <StyledListIcon>
                <PaymentIcon />
              </StyledListIcon>
              {teachList[3]}
            </li>
          </StyledIconList>
        </MobileOnlyMaxWidthContent>
      </StyledTeachSection>
    </StyledContainer>
  )
}

export default SampleAndTeachSection
